<template>
    <div>
        <big-title-color variant-class="dark">
            <template v-slot:text>Activar<span> pago automático</span>.</template>.
        </big-title-color>
        <!-- CARD DA EL PASO -->
        <vx-card class="mt-base"
            title="¡Da el paso hacia un camino financiero más simple y exitoso activando el pago automático ahora mismo, es muy sencillo!">
            <p class="text-md">
                No más preocupaciones por fechas de pago. Activa el pago automático de
                tu crédito y experimenta la comodidad de un proceso sin complicaciones.
                Convierte el estrés de los pagos a tiempo en cosa del pasado, liberando
                tiempo para lo que realmente importa en tu vida. Además, al mantener un
                historial de pagos puntual, estarás fortaleciendo tu perfil crediticio,
                abriendo puertas a mejores oportunidades financieras en el futuro. No
                dejes que los olvidos o el tiempo consumido por los pagos manuales te
                detengan.
            </p>
        </vx-card>
        <!-- CARD DA EL PASO -->

        <vx-card class="mt-base" title="¡Actívalo ahora mismo en 2 pasos!">
            <div class="vx-row mt-6 mb-base">
                <div class="vx-col w-full">
                    <div class="flex items-start justify-left mb-1">
                        <p class="mbot"><span class="list-number">1</span></p>
                        <p class="ml-3">
                            <b>Selecciona tu cuenta</b><br>
                            Selecciona la cuenta desde la que quieres que se realice el pago automático
                        </p>
                    </div>
                </div>
                <div class="vx-col lg:w-2/3 md:w-3/4 sm:w-full w-full ml-base">
                    <div v-if="accounts.length > 0" id="data-list-account-view" class="data-list-container">
                        <vs-table class="mt-5" ref="accountsTable"
                            :data="accounts" noDataText="No hay cuentas disponibles">
                            <template slot="thead">
                                <vs-th class="bold">BANCO</vs-th>
                                <vs-th class="bold">CLABE</vs-th>
                            </template>
                            <template slot-scope="{ data }">
                                <tbody>
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" style="background-color: #3B3A3E; color: white; border-bottom: solid white;">
                                        <vs-td>
                                            <div class="flex items-center justify-left">
                                                <vx-tooltip v-if="!tr.direct_billing_acceptance"
                                                title="Cuenta no soportada"
                                                text="Esta cuenta de banco no esta habilitada para poder realizar domiciliación.">
                                                <feather-icon icon="AlertTriangleIcon" svgClasses="h-5 w-5 text-danger" class="mr-2" />
                                                </vx-tooltip>
                                                <vs-radio v-else class="mr-3" color="success" v-model="selectedAccountId" :vs-value="tr.value"></vs-radio>
                                                {{ tr.bank }}
                                            </div>
                                        </vs-td>
                                        <vs-td>
                                            {{ tr.clabe }}
                                        </vs-td>
                                    </vs-tr>
                                </tbody>
                            </template>
                        </vs-table>
                        <div class="flex justify-left mt-3">
                            <feather-icon icon="InfoIcon" svgClasses="w-5 h-5 text-success" />
                            <small class="ml-1">
                                <i>
                                    El pago se realizará el día que te corresponda según tu plan de pagos, pero en caso de no haber fondos suficientes serás notificado y te corresponderá hacer el pago de manera manual ese mes.
                                </i>
                            </small>
                        </div>
                    </div>

                    <div style="border-left: solid #CBFF00;" v-else>
                        <div class="flex justify-left items-center mt-3 ml-3">
                            <feather-icon icon="AlertTriangleIcon" svgClasses="w-6 h-6 text-danger" />
                            <b class="ml-2">Aún no tienes una cuenta registrada</b>
                        </div>
                        <p class="mt-2 ml-3">
                            Envía por lo menos $1 MXN para dar de alta tu cuenta bancaria. Importante: Es necesario que el banco se encuentre a tu nombre, 
                            de lo contrario no podremos recibir los fondos ni domiciliar el abono recurrente.
                            <br>
                            <br>
                            Banco: STP (Sistema de Transferencias y Pagos)<br>
                            CLABE: {{ stpClabe }}<br>
                            Beneficiario: Aquí pones tu nombre<br>
                            Método de transferencia: SPEI
                        </p>
                    </div>
                </div>
            </div>
            <div class="vx-row mt-6 mb-base">
                <div class="vx-col w-full">
                    <div class="flex items-start justify-left mb-3">
                        <p class="mbot"><span class="list-number">2</span></p>
                        <p class="ml-3">
                            <b>Acepta los términos</b><br>
                            Al dar clic en Confirmar y activar pago automático, confirmarás que RedGirasol podrá realizar la domiciliación a tu cuenta de banco elegida. No te preocupes, podrás cancelar el pago automático en cualquier momento.                        </p>
                    </div>
                </div>
                <div class="vx-col lg:w-2/3 md:w-3/4 sm:w-full w-full ml-base">
                    <vs-button size="small" color="#20B61A" type="border" :disabled="!selectedAccountId" @click="popUpDirectBillingAcceptanceContract=true">Ver consentimiento de pago</vs-button>
                    <vs-checkbox class="mt-5" color="success" :disabled="!selectedAccountId" v-model="termsAccepted">He leído y doy el consentimiento</vs-checkbox>
                    <vs-button color="success" class="mt-5" :disabled="!termsAccepted" @click="acceptDirectBilling">Confirmar y activar pago automático</vs-button>
                </div>
            </div>
        </vx-card>
        <!-- Popup para tabla de amortización consolidada -->
        <vs-popup :active.sync="popUpDirectBillingAcceptanceContract" title="Consentimiento de pago" class="extended-popup header-primary">
            <latest-direct-billing-acceptance-contract v-if="selectedAccount" :bank-account="selectedAccount" />
        </vs-popup>
        
        <!-- Componente para obtener la geolocalización -->
        <rg-geolocator ref="rgGeolocator" />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import formatHelper from "@mixins/formatHelper";
import dateHelper from "@mixins/dateHelper";
import LatestDirectBillingAcceptanceContract from "@views/share/contracts/DirectBillingContract/LatestDirectBillingAcceptanceContract.vue";
import {BankAccountStatus} from "@/bl/BankAccountStatus";

export default {
    name: "AutomaticPaymentsPage",
    mixins: [formatHelper, dateHelper],
    components: {
        LatestDirectBillingAcceptanceContract
    },
    data() {
        return {
            contractAcceptDirectBillingType: 19,
            isMounted: false,
            failed: false,
            validAccounts: [],
            accounts: [],
            stpClabe: null,
            selectedAccountId: null,
            mexicoCountryId: 700,
            termsAccepted: false,
            popUpDirectBillingAcceptanceContract: false
        };
    },
    async mounted() {
        if (this.user.payment_method == 3) {
            await this.$router.push({ name: 'realizarPagosSolicitante' });
            return;
        }
        this.showLoading(true);
        this.isMounted = false;
        await this.getUserAccounts();
        this.stpClabe = this.user.clabeStp; 
        if (!this.stpClabe) {
            await this.getApplicantPaymentInfo();
        }
        // se pide la geolocalizacion para la operacion
        await this.$refs.rgGeolocator.askForGeolocation();
        this.isMounted = true;
        this.showLoading(false);
    },
    computed: {
        selectedAccount(){
            return this.validAccounts.find((f) => f.id == this.selectedAccountId);
        },
    },
    filters: {
        human_date(date) {
            return moment(date).lang("es").format("D MMM YYYY");
        },
    },
    methods: {
        ...mapActions('auth', ['getUserInfo']),
        async getUserAccounts() {
            try {
                const res = await axios.get(`api/v1/userBankAccounts/${this.UserId}`);
                const accs = res.data;
                let formattedAccounts = [];
                accs.forEach((f) => {
                    let nacc = this.getFormattedAccount(f);
                    formattedAccounts.push(nacc);
                });

                this.validAccounts = formattedAccounts.filter((f) => f.is_verified);
                this.validAccounts.sort(function (a, b) {
                    if (a.is_default) {
                        return -1;
                    } else if (b.is_default) {
                        return 1;
                    }
                    return 0;
                });

                this.validAccounts.forEach((opt) => {
                    this.accounts.push({
                        value: parseInt(opt.id),
                        bank: opt.bank_name,
                        clabe: opt.clabe,
                        direct_billing_acceptance: opt.direct_billing_acceptance
                    }); //conversion a sintaxis de vx-select
                });

                const defaultAccount = this.validAccounts.find((f) => f.is_default);
                if (defaultAccount != null) {
                    this.selectedAccountId = defaultAccount.id;
                }
            } catch (e) { }
        },
        getFormattedAccount(f) {
            let nacc = {
                id: f.id,
                bank_id: f.bank_id,
                is_default: f.is_default === 1,
                is_verified: f.verified === BankAccountStatus.VERIFIED,
                direct_billing_acceptance: f.bank.direct_billing_acceptance
            };

            if (f.bank && f.bank.country_id === this.mexicoCountryId) {
                nacc.bank_name = f.bank.short_name;
                nacc.clabe = f.clabe;
            } else {
                nacc.bank_name = f.bank.name;
                nacc.clabe = f.bic_swift;
            }
            return nacc;
        },
        async getApplicantPaymentInfo(){
            try{
                const response = await axios.get(`api/v1/applicant/${this.ApplicantId}/paymentData`);
                this.stpClabe = response.data.clabe_stp;
            }
            catch (e) {
                this.failed = true;
            }
        },
        async acceptDirectBilling() {
            if(!this.selectedAccount.direct_billing_acceptance){
                this.errorNotifDialog('Cuenta no soportada', "La cuenta de banco que indicas no esta habilitada para poder realizar domiciliación.");
                return false;
            }
            this.showLoading(true, "Guardando información...");
            try {
                let payload = {
                    geolocation: this.CurrentGeolocationData,
                    contractType: this.contractAcceptDirectBillingType,
                    lowerPeriodicity: this.user.lower_periodicity,
                    bankAccountId: this.selectedAccountId
                };
                await axios.post("/api/v1/applicant/" + this.ApplicantId + "/acceptDirectBilling", payload);
                await this.getUserInfo(this.UserRole);
                this.showLoading(false);
                await this.$router.push({ name: 'realizarPagosSolicitante' });
            } catch (error) {
                this.showLoading(false);
                if (typeof error.response.data.message !== 'undefined') {
                    this.errorNotifDialog('Operación fallida', error.response.data.message);
                } else {
                    this.failedOperationNotif();
                }
            }
        }
    },
};
</script>

<style>
.list-number {
  font-size: 16px;
  background-color: #CBFF00;
  color: black;
  padding: 2px 11px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 5px;
  font-weight: bold;
}

.mbot {
  margin-bottom: 15px;
}
</style>